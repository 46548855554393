import useScrollTrigger from '@mui/material/useScrollTrigger';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { AuthContextValue, useAuthState } from '@src/providers/AuthProvider';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import { useModal } from '@src/providers/ModalProvider';
import { AuthScreenType } from '@src/utils/enums';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

const DefaultTextClassName = 'text-content-t300 text-xs font-semibold text-center';
const SelectedTextClassName = 'text-primary-p4 text-xs font-semibold text-center';

const Authentication = dynamic(() => import('@components/modal/authentication/Authentication'));
const Slide = dynamic(() => import('@mui/material/Slide'));

// Assets
import IconCommunityBlackSVG from '/public/ic-community-black.svg';
import IconCommunityPrimaryThickSVG from '/public/ic-community-primary-thick.svg';

import IconDiscoveryBlackSVG from '/public/ic-discovery-black.svg';
import IconDiscoveryPrimaryThickSVG from '/public/ic-discovery-primary-thick.svg';

import IconMarketBlackSVG from '/public/ic-market-black.svg';
import IconMarketPrimaryThickSVG from '/public/ic-market-primary-thick.svg';

import IconEmailBlackSVG from '/public/ic-email-black.svg';
import IconEmailPrimaryThickSVG from '/public/ic-email-primary-thick.svg';

import IconProfileBlackSVG from '/public/ic-profile-black.svg';

import { customImageLoader } from '@src/utils/media';
import { useTranslation } from 'react-i18next';
import IconInboxSVG from '/public/ic-inbox-noti.svg';

/**
 * A bottom navigation app bar that shows on mobile.
 *
 * Reference: https://airbnb.com (mobile)
 */
function BottomAppBar() {
  /* Hooks */
  const router = useRouter();
  const currentPath = router.pathname;

  // Get authenticated data
  const { user } = useAuthState() as AuthContextValue;
  const { unreadUserChannelsQuantity } = useChatState() as ChatContextValue;

  // The mobile bottom app bar should only display when scrolling down
  const scrollTrigger = useScrollTrigger({ disableHysteresis: false, threshold: 500 });
  const { setModalContent } = useModal();

  const { isMobile } = useWindowDimensions();

  const { t } = useTranslation('common');
  const hasNewMessage = unreadUserChannelsQuantity > 0;

  // Only show bottom app bar on mobile
  if (!isMobile) return null;

  /* Views */

  return (
    <Slide appear={false} direction="up" in={!scrollTrigger}>
      <nav
        className="grid grid-cols-5 z-20 fixed bottom-0 w-full px-2 py-3 bg-gray-50
          border-t border-greyscale-g3 items-center justify-evenly"
      >
        <Link href="/" passHref prefetch={isMobile}>
          <a className="flex flex-col items-center">
            <div className="flex items-center justify-center w-6 h-6">
              {currentPath === '/' ? <IconCommunityPrimaryThickSVG /> : <IconCommunityBlackSVG />}
            </div>
            <span className={currentPath === '/' ? SelectedTextClassName : DefaultTextClassName}>
              {t('Khám phá')}
            </span>
          </a>
        </Link>
        <Link href="/artworks" passHref prefetch={isMobile}>
          <a className="flex flex-col items-center">
            <div className="flex items-center justify-center w-6 h-6">
              {currentPath === '/artworks' ? (
                <IconDiscoveryPrimaryThickSVG />
              ) : (
                <IconDiscoveryBlackSVG />
              )}
            </div>
            <span
              className={currentPath === '/artworks' ? SelectedTextClassName : DefaultTextClassName}
            >
              {t('Phòng tranh')}
            </span>
          </a>
        </Link>
        <Link href="/jobs" passHref prefetch={isMobile}>
          <a className="flex flex-col items-center">
            <div className="flex items-center justify-center w-6 h-6">
              {currentPath === '/jobs' ? <IconMarketPrimaryThickSVG /> : <IconMarketBlackSVG />}
            </div>
            <span
              className={currentPath === '/jobs' ? SelectedTextClassName : DefaultTextClassName}
            >
              {t('Chợ comm')}
            </span>
          </a>
        </Link>
        {/* If user is not authenticated, show the login modal */}
        {!user && (
          <button
            className="flex flex-col items-center"
            onClick={() => {
              setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
            }}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <IconEmailBlackSVG />
            </div>
            <span
              className={currentPath === '/users' ? SelectedTextClassName : DefaultTextClassName}
            >
              {t('Tin nhắn')}
            </span>
          </button>
        )}
        {/* If user is not authenticated, navigate to the inbox tab */}
        {!!user && (
          <Link href="/inbox" prefetch={isMobile}>
            <a className="flex flex-col items-center">
              <div className="relative flex items-center justify-center w-6 h-6">
                {currentPath === '/inbox' ? <IconEmailPrimaryThickSVG /> : <IconEmailBlackSVG />}

                {hasNewMessage && (
                  <div className="absolute -top-1 -right-2 text-m font-semibold">
                    <div className="flex items-center justify-center w-2 h-2">
                      <IconInboxSVG />
                    </div>
                  </div>
                )}
              </div>
              <span
                className={currentPath === '/inbox' ? SelectedTextClassName : DefaultTextClassName}
              >
                {t('Tin nhắn')}
              </span>
            </a>
          </Link>
        )}

        {/* If user is not authenticated, show the login modal */}
        {!user && (
          <button
            className="flex flex-col items-center"
            onClick={() => {
              setModalContent(<Authentication defaultScreenType={AuthScreenType.MAIN} />);
            }}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <IconProfileBlackSVG />
            </div>
            <span className={DefaultTextClassName}>{t('Đăng nhập')}</span>
          </button>
        )}
        {/* If user is authenticated, navigate to the profile link */}
        {!!user && (
          <Link href={`/users/profile`} prefetch={isMobile}>
            <a className="flex flex-col items-center">
              <div className="flex justify-center rounded-full relative h-6 w-6">
                {/* Authenticated and has profile picture */}
                {!!user.profile_picture_url && (
                  <Image
                    alt={`Profile photo of ${user.displayName}`}
                    className="rounded-full"
                    layout="fill"
                    objectFit="cover"
                    src={user.profile_picture_url || ''}
                    loader={customImageLoader}
                    sizes="24px"
                  />
                )}
                {/* Authenticated but no profile picture */}
                {!user.profile_picture_url && (
                  <div className="flex items-center justify-center rounded-full w-full h-full bg-secondary-s3 text-s">
                    {user?.displayName.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              <span
                className={
                  router.asPath === `/users/profile` ? SelectedTextClassName : DefaultTextClassName
                }
              >
                {t('Cá nhân')}
              </span>
            </a>
          </Link>
        )}
      </nav>
    </Slide>
  );
}

export default BottomAppBar;
